import React from 'react';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

const {
  REACT_APP_VERSION,
  REACT_APP_GIT_COMMIT,
  REACT_APP_GIT_BRANCH,
  REACT_APP_GIT_TAG,
} = process.env;

const AppVersion = ({
  ver, tag, commit, branch,
}) => {
  const content = () => [
    ver ? REACT_APP_VERSION : '',
    tag ? REACT_APP_GIT_TAG : '',
    commit ? REACT_APP_GIT_COMMIT : '',
    branch ? REACT_APP_GIT_BRANCH : '',
  ].filter((s) => s.length > 0)
    .join('-');

  return (
    <Stack
      alignItems="flex-start"
      justifyContent="flex-start"
    >
      <Typography
        variant="caption"
        style={{ color: 'lightgray', margin: '10px' }}
      >
        { content() }
      </Typography>
    </Stack>
  );
};

AppVersion.propTypes = {
  ver: PropTypes.bool,
  tag: PropTypes.bool,
  commit: PropTypes.bool,
  branch: PropTypes.bool,
};

AppVersion.defaultProps = {
  ver: false,
  tag: true,
  commit: true,
  branch: false,
};

export default AppVersion;
