import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, Snackbar, Alert } from '@mui/material';

const Toast = ({
  isOpen, dismiss, message, severity, duration,
}) => (
  <Backdrop
    sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    open={isOpen}
    onClick={dismiss}
  >
    <Snackbar
      open={isOpen}
      // use 'height' to make it centered
      style={{ height: '100%' }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      autoHideDuration={duration}
      onClose={dismiss}
    >
      <Alert severity={severity}>
        { message }
      </Alert>
    </Snackbar>
  </Backdrop>
);

Toast.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dismiss: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.string,
  duration: PropTypes.number,
};

Toast.defaultProps = {
  severity: 'success',
  duration: 2000,
};

export default Toast;
