/* eslint-disable react/prop-types */
import React, { useState, createContext } from 'react';
import ErrorAlert from '../components/ErrorAlert';

export const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  return (
    <ErrorContext.Provider value={{ error, setError }}>
      <ErrorAlert
        isOpen={!!error}
        dismiss={() => setError(null)}
        error={error}
      />
      { children }
    </ErrorContext.Provider>
  );
};
