/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    borderRadius: '15px',
    padding: '7px 25px',
    textTransform: 'none',
    fontWeight: 'bold',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.light,
    },
    '&:disabled': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.light,
    },
    minWidth: '200px',
    maxWidth: '350px',
  },
});

const MillaButton = withStyles(styles)((props) => <Button variant="contained" {...props} />);

export { MillaButton };
