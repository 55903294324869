import React from 'react';
import PropTypes from 'prop-types';
import { Stack, SvgIcon, Typography } from '@mui/material';
import { Box } from '@mui/system';
import makeStyles from '@mui/styles/makeStyles';


const MillaLogo = (props) => {
  const useStyles = makeStyles(() => ({
    logoSize: p => ({
      width: p.emsize,
      height: p.emsize
    })
  }));

  const classes = useStyles(props)
  
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <SvgIcon viewBox="0 0 50 50" className={classes.logoSize}>
          <path d="M 17.683594 38.085938 L 19.25 38.085938 L 19.25 12.3125 L 17.679688 12.3125 L 16.652344 13.121094 L 16.652344 37.257812 Z M 17.683594 38.085938 " />
          <path d="M 17.683594 12.316406 L 19.25 12.316406 L 25.867188 25.199219 L 24.300781 25.199219 Z M 17.683594 12.316406 " />
          <path d="M 32.492188 38.085938 L 30.929688 38.085938 L 24.308594 25.199219 L 25.875 25.199219 Z M 32.492188 38.085938 " />
          <path d="M 32.484375 12.316406 L 30.921875 12.316406 L 24.300781 25.199219 L 25.871094 25.199219 Z M 32.484375 12.316406 " />
          <path d="M 32.492188 12.316406 L 30.929688 12.316406 L 30.929688 38.085938 L 32.496094 38.085938 L 33.523438 37.28125 L 33.523438 13.144531 Z M 32.492188 12.316406 " />
          <path d="M 25.089844 47.910156 C 12.464844 47.910156 2.230469 37.742188 2.230469 25.199219 C 2.230469 12.65625 12.464844 2.488281 25.089844 2.488281 C 37.714844 2.488281 47.953125 12.65625 47.953125 25.199219 C 47.953125 25.894531 47.921875 26.578125 47.859375 27.257812 C 46.8125 38.835938 37.019531 47.910156 25.089844 47.910156 Z M 25.089844 1.925781 C 11.234375 1.925781 0 12.347656 0 25.199219 C 0 38.054688 11.234375 48.472656 25.089844 48.472656 C 38.949219 48.472656 50.183594 38.054688 50.183594 25.199219 C 50.183594 12.347656 38.949219 1.925781 25.089844 1.925781 Z M 25.089844 1.925781 " />
        </SvgIcon>
      </Box>

      <Typography
        style={{ textTransform: 'uppercase' }}
      >
        {props.subtitle}
      </Typography>
    </Stack>
  )
};

MillaLogo.propTypes = {
  subtitle: PropTypes.string,
  emsize: PropTypes.string
};

MillaLogo.defaultProps = {
  subtitle: 'Stock Point',
  emsize: "5em"
};

export default MillaLogo;