/* eslint-disable import/prefer-default-export */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

class DialogOption {
  constructor(id, title, isDefault) {
    this.id = id;
    this.title = title;
    this.isDefault = isDefault;
  }

  static cancel = new DialogOption('cancel', 'Cancel');

  static ok = new DialogOption('ok', 'OK', true);
}

const ConfirmDialog = ({
  isOpen, title, content, handler, options,
}) => (
  <Dialog
    open={isOpen}
    // Use first option as dismiss handler
    onClose={() => handler(options[0].id)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      { title }
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        { content }
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      { options.map((opt) => (
        <Button
          key={opt.id}
          onClick={() => handler(opt.id)}
          autoFocus={opt.isDefault}
        >
          { opt.title }
        </Button>
      )) }
    </DialogActions>
  </Dialog>
);

ConfirmDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  handler: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.instanceOf(DialogOption),
  ).isRequired,
};

export { DialogOption, ConfirmDialog };

const dialogRoot = document.createElement('div');
const body = document.querySelector('body');
body.appendChild(dialogRoot);

const confirm = ({ title, content, options }) => new Promise((resolve) => {
  const finish = (result) => {
    ReactDOM.unmountComponentAtNode(dialogRoot);
    resolve(result);
  };
  ReactDOM.render(
    <ConfirmDialog
      isOpen
      title={title}
      content={content}
      handler={finish}
      options={options}
    />, dialogRoot,
  );
});

export { confirm };
