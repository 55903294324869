/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import { MillaButton } from './MillaStyled';
import { ReactComponent as GoogleIcon } from '../resources/google.svg';
import { ReactComponent as FacebookIcon } from '../resources/facebook.svg';
import { ReactComponent as AppleIcon } from '../resources/apple-black-logo.svg';

const FacebookButton = (props) => (
  <MillaButton
    style={{ background: '#1877F2', width: '100%', justifyContent: "flex-start" }}
    startIcon={<FacebookIcon />}
    variant="outlined"
    {...props}
  >
    Sign in with Facebook
  </MillaButton>
);

const GoogleButton = (props) => (
  <MillaButton
    style={{ background: 'transparent', color: 'black', width: '100%', justifyContent: "flex-start" }}
    startIcon={<GoogleIcon />}
    variant="outlined"
    {...props}
  >
    Sign In with Google
  </MillaButton>
);

const AppleButton = (props) => (
  <MillaButton
    style={{ background: 'transparent', color: 'black', width: '100%', justifyContent: "flex-start" }}
    startIcon={<AppleIcon />}
    variant="outlined"
    {...props}
  >
    Sign In with Apple
  </MillaButton>
);


const EmailButton = (props) => (
  <MillaButton
    style={{ width: '100%' }}
    startIcon={<EmailIcon />}
    variant="outlined"
    {...props}
  >
    Continue with Email
  </MillaButton>
);

export { FacebookButton, GoogleButton, AppleButton, EmailButton };
