/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children, onAuthChange }) => {
  const [isResolved, setIsResolved] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    onAuthChange((user) => {
      setCurrentUser(user);
      setIsResolved(true);
    });
  }, []);

  return (
    <AuthContext.Provider value={{ isResolved, currentUser }}>
      { isResolved && children }
    </AuthContext.Provider>
  );
};
