class ProductStatus {
    static options = [
      { value: 'pending', name: 'Pending' },
      { value: 'instore', name: 'In-Store' },
      { value: 'sold', name: 'Sold' },
    ];
  
    static nameOf(value) {
      return ProductStatus.options.find((el) => el.value === value)?.name;
    }
  }
  
  export default ProductStatus;
  