/* eslint-disable react/jsx-props-no-spreading */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const PrivateRoute = ({ component: Component, signInLocation, ...rest }) => {
  if (rest.onResolve) {
    rest.onResolve(rest.computedMatch.params);
  }
  const { currentUser } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        currentUser
          ? <Component {...routeProps} />
          : <Redirect to={signInLocation} />
      )}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  signInLocation: PropTypes.string,
};

PrivateRoute.defaultProps = {
  signInLocation: '/signin',
};

export default PrivateRoute;
