import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CircularProgress } from '@mui/material';

const LoadingAlert = ({ isOpen, title }) => (
  <>
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent style={{
        display: "flex",
        allignItems: "center",
        justifyContent: "center"
      }}>
        <CircularProgress />
      </DialogContent>
    </Dialog>
  </>
);

LoadingAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

LoadingAlert.defaultProps = {
  title: 'Loading...',
};

export default LoadingAlert;
