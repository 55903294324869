import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ErrorAlert = ({ isOpen, dismiss, error }) => (
  <>
    <Dialog
      open={isOpen}
      onClose={() => dismiss()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Error</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description" color="error">
          {error}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dismiss()} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  </>
);

ErrorAlert.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  dismiss: PropTypes.func.isRequired,
  error: PropTypes.string,
};

ErrorAlert.defaultProps = {
  error: '',
};

export default ErrorAlert;
